export default function() {
  fetch("/csrf_tokens/validate", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      "X-CSRF-Token": document.querySelector('meta[name="csrf-token"]').content
    },
    credentials: 'same-origin'
  }).then((response) => {
    if(response.ok) {
      return response.json()
    }
  }).then((responseJSON) => {
    if (!responseJSON.valid) {
      window.location.reload(true)
    }
  })
}
