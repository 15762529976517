import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["editor", "templateSource", "livePreview"]

  initialize() {
    this.livePreviewPath = this.data.get("livePreviewPath")

    this.editor = ace.edit(this.editorTarget);
    this.editor.setOptions({
      wrap: true,
      theme: "ace/theme/chrome",
      mode: "ace/mode/markdown",
      fontSize: 16,
    })
    this.editor.setValue(this.templateSourceTarget.value)
    this.editor.clearSelection()
    this.editor.session.on('change', this.templateSourceChanged.bind(this))

    this.stalePreview = true
    this.refreshPreview()
    setInterval(this.refreshPreview.bind(this), 2000)
  }

  templateSourceChanged() {
    this.stalePreview = true
    this.templateSourceTarget.value = this.editor.getValue()
  }

  refreshPreview() {
    if (!this.stalePreview) {
      return
    }
    this.stalePreview = false

    let csrfTokenElement = document.querySelector('meta[name="csrf-token"]')
    let csrfToken;
    if(csrfTokenElement) {
      csrfToken = csrfTokenElement.content
    }

    fetch(this.livePreviewPath, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "X-CSRF-Token": csrfToken
      },
      credentials: 'same-origin',
      body: JSON.stringify({template_source: this.editor.getValue()})
    }).then(response => {
      return response.text()
    }).then(text => {
      this.livePreviewTarget.innerHTML = text
    }).catch(error => {
      console.log("Failed to get live preview: ", error.message)
    });
  }
}
