import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["userName", "merchantQuoteURL"]

  initialize() {
    this.baseDomain = this.data.get("baseDomain")
  }

  userNameChanged(e) {
    this.merchantQuoteURLTarget.value = this.baseDomain + e.currentTarget.value
  }
}
