import { Controller } from "stimulus"
import Player from '@vimeo/player'

export default class extends Controller {
  initialize() {
    this.vimeoId = this.data.get("vimeoId")
    this.duration = this.data.get("duration")
    this.updateDurationPath = this.data.get("updateDurationPath")

    this.player = new Player(this.element, {
      id: this.vimeoId,
      responsive: 1
    })

    this.player.getDuration().then(duration => {
      this.sendDuration(duration)
    }).catch(error => {
      console.log(error)
    });
  }

  sendDuration(duration) {
    let csrfTokenElement = document.querySelector('meta[name="csrf-token"]')
    let csrfToken;
    if(csrfTokenElement) {
      csrfToken = csrfTokenElement.content
    }

    fetch(this.updateDurationPath, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "X-CSRF-Token": csrfToken
      },
      credentials: 'same-origin',
      body: JSON.stringify({duration: duration})
    }).then(response => {
      if(response.ok) {
        return
      }
      throw new Error('Bad network response')
    }).catch(error => {
      console.log("Failed to update duration: ", error.message)
    });
  }
}
